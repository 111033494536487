import * as React from "react";
import Img from "gatsby-image";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { Header } from "../components/header";

const Home = () => {
  const data = useStaticQuery(graphql`
    query {
      n1: file(relativePath: { eq: "img/1.jpg" }) {
        childImageSharp {
          fixed(width: 200, height: 200) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      n2: file(relativePath: { eq: "img/2.jpg" }) {
        childImageSharp {
          fixed(width: 200, height: 200) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      n3: file(relativePath: { eq: "img/3.jpg" }) {
        childImageSharp {
          fixed(width: 200, height: 200) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      n4: file(relativePath: { eq: "img/4.jpg" }) {
        childImageSharp {
          fixed(width: 200, height: 200) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      n5: file(relativePath: { eq: "img/5.jpg" }) {
        childImageSharp {
          fixed(width: 200, height: 200) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      n6: file(relativePath: { eq: "img/6.jpg" }) {
        childImageSharp {
          fixed(width: 200, height: 200) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      n7: file(relativePath: { eq: "img/7.jpg" }) {
        childImageSharp {
          fixed(width: 200, height: 200) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      n8: file(relativePath: { eq: "img/8.jpg" }) {
        childImageSharp {
          fixed(width: 200, height: 200) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      n9: file(relativePath: { eq: "img/9.jpg" }) {
        childImageSharp {
          fixed(width: 200, height: 200) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  return (
    <>
      <Helmet htmlAttributes={{ lang: "en" }} bodyAttributes={{ class: "bg-white text-gray-900 dark:bg-gray-900 dark:text-white" }}>
        <meta charSet="utf-8" />
        <title>Archive Cat</title>
        <meta name="description" content="Archive Cat provides reliable storage for unchanging files." />
      </Helmet>
      <div>
        <Header />
        <main className="relative max-w-7xl mx-auto mt-4">
          <div className="relative w-full lg:w-1/2 lg:pb-1/2">
            <div className="lg:absolute pl-2 lg:pr-8 ">
              <div className="">
                <h1 className="text-3xl font-bold">
                  <span className="">Reliable storage for </span>
                  <span className="text-brand inline-block">digital memories</span>
                </h1>
                <p className="mt-3 max-w-md text-lg sm:text-xl md:mt-5 md:max-w-3xl">
                  Store photos, videos, and other files in our secure cloud, protected from loss, expiration, and deletion. Access files
                  through an ad-free site optimized for performance.
                </p>
              </div>
              <div className="p-4 w-full items-center">
                <div className="rounded-md shadow lg:float-right lg:w-64">
                  <a
                    href="#"
                    className="w-full flex items-center justify-center py-3 border border-transparent text-base font-medium rounded-md text-white bg-brand hover:bg-brand-800 dark:hover:bg-white dark:hover:text-brand md:py-4 md:text-lg"
                  >
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="relative w-full pb-1/2 hidden lg:block lg:absolute lg:inset-y-0 lg:right-2 lg:w-1/2 lg:pb-1/2">
            <div className="absolute top-0 h-full w-full lg:rounded-lg shadow-lg grid grid-cols-3 gap-1 overflow-hidden">
              <div>
                <Img style={{ height: "100%", width: "100%" }} imgStyle={{ objectFit: "cover" }} fixed={data.n1.childImageSharp.fixed} />
              </div>
              <div>
                <Img style={{ height: "100%", width: "100%" }} imgStyle={{ objectFit: "cover" }} fixed={data.n2.childImageSharp.fixed} />
              </div>
              <div>
                <Img style={{ height: "100%", width: "100%" }} imgStyle={{ objectFit: "cover" }} fixed={data.n3.childImageSharp.fixed} />
              </div>
              <div>
                <Img style={{ height: "100%", width: "100%" }} imgStyle={{ objectFit: "cover" }} fixed={data.n4.childImageSharp.fixed} />
              </div>
              <div>
                <Img style={{ height: "100%", width: "100%" }} imgStyle={{ objectFit: "cover" }} fixed={data.n5.childImageSharp.fixed} />
              </div>
              <div>
                <Img style={{ height: "100%", width: "100%" }} imgStyle={{ objectFit: "cover" }} fixed={data.n6.childImageSharp.fixed} />
              </div>
              <div>
                <Img style={{ height: "100%", width: "100%" }} imgStyle={{ objectFit: "cover" }} fixed={data.n7.childImageSharp.fixed} />
              </div>
              <div>
                <Img style={{ height: "100%", width: "100%" }} imgStyle={{ objectFit: "cover" }} fixed={data.n8.childImageSharp.fixed} />
              </div>
              <div>
                <Img style={{ height: "100%", width: "100%" }} imgStyle={{ objectFit: "cover" }} fixed={data.n9.childImageSharp.fixed} />
              </div>
            </div>
          </div>
          {/* Not sure why this one needs grid, but it does in Safari https://stackoverflow.com/questions/55347359/why-is-css-grid-row-height-different-in-safari */}
          <div className="relative w-full pb-1/3 block lg:hidden grid">
            <div className="absolute top-0 h-full w-full lg:rounded-lg shadow-lg grid grid-cols-3 gap-1 overflow-hidden">
              <div className="h-full">
                <Img style={{ height: "100%", width: "100%" }} imgStyle={{ objectFit: "cover" }} fixed={data.n4.childImageSharp.fixed} />
              </div>
              <div className="h-full">
                <Img style={{ height: "100%", width: "100%" }} imgStyle={{ objectFit: "cover" }} fixed={data.n5.childImageSharp.fixed} />
              </div>
              <div className="h-full">
                <Img style={{ height: "100%", width: "100%" }} imgStyle={{ objectFit: "cover" }} fixed={data.n6.childImageSharp.fixed} />
              </div>
            </div>
          </div>
        </main>
        <div className="max-w-7xl mx-auto mt-4 px-4">
          <p className="text-base text-center lg:text-right">&copy; 2020 Archive Cat, LLC. All rights reserved.</p>
        </div>
      </div>
    </>
  );
};

export default Home;
